export const NOTIFICATION_FULL_FRAGMENT = `
fragment notificationFullFragment on Notification {
  uid
  action
  interactedWith: _interactedWith(myUid: "%authUser%")
  initiator {
    uid
    __typename
    ... on CommunityUser {
      firstName
      lastName
      pictureFileResource {
        path
        schemaCode
      }
    }
    ... on Exhibitor {
      name
      logoFileResource {
        path
        schemaCode
      }
    }
    ... on Session {
      name
    }
    ... on Meeting {
      subject
    }
    ... on CustomNotification {
      uid
      scheduleTime
      scheduleTimestamp
      message
      actionUrl
      actionText
    }
    ... on Survey {
      uid
      code
      title
      strategy
      instructions
      startTime
      startTimestamp
      endTime
      endTimestamp
    }
  }
  triggered {
    uid
    __typename
    ... on CommunityUserConnection {
      linkState
      deleted
    }
    ... on CompanyUserRole {
      state
      user: companyUser {
        uid
      }
    }
    ... on MeetingParticipant {
      state
      meeting {
        uid
        isCancelled
        subject
        description
        location
        startTime
        endTime
        startTimestamp
        endTimestamp
        timeZoneName
        creatorUser {
          uid
          firstName
          lastName
          pictureFileResource {
            uid
            schemaCode
            path
          }
        }
        participants {
          uid
          state
          user {
            uid
            firstName
            lastName
            pictureFileResource {
              uid
              schemaCode
              path
            }
          }
        }
      }
    }
    ... on Meeting {
      uid
      isCancelled
      subject
      description
      location
      startTime
      endTime
      startTimestamp
      endTimestamp
      timeZoneName
      creatorUser {
        uid
        firstName
        lastName
        pictureFileResource {
          uid
          schemaCode
          path
        }
      }
      participants {
        uid
        state
        user {
          uid
          firstName
          lastName
          pictureFileResource {
            uid
            schemaCode
            path
          }
        }
      }
    }
    ...on FeedPost {
      uid
      _feedItem {
        uid
        wrapper {
          uid
        }
      }
    }
    ...on Comment {
          uid
          textContent
          parentComment {
            uid
          }
          post {
            _feedItem {
              uid
              wrapper {
                uid
              }
            }
            uid
          }
        }
    ... on Session {
      name
      surveyUrl
    }
    ... on MeetingRequest {
      uid
      availability
      readState
      timezoneName
      exhibitor {
        uid
        name
      }
      meetingRequestMeeting {
        uid
        isCancelled
        subject
        description
        location
        startTime
        endTime
        startTimestamp
        endTimestamp
        timeZoneName
        creatorUser {
          uid
          firstName
          lastName
          pictureFileResource {
            uid
            schemaCode
            path
          }
        }
        participants {
          uid
          state
          user {
            uid
            firstName
            lastName
            pictureFileResource {
              uid
              schemaCode
              path
            }
          }
        }
      }
    }
    ... on Message {
      content
      group {
        uid
        target {
          ... on Session {
            uid
            name
            description
          }
        }
      }
    }
    ... on Survey {
      uid
      notificationMessage
    }
    ... on CustomNotification {
      uid
      scheduleTime
      scheduleTimestamp
      message
      actionUrl
      actionText
    }
  }
  notifiedUsers(filter: {uid: "%authUser%"}) {
    uid
    __typename
  }
  createdTime
  createdTimestamp
}`;
